import "bootstrap";

import { close_modal } from "./close-modals";

const dkrocket = {
    hello: 42,
};

close_modal();

export default dkrocket;
